<template>
  <div>
    <h1 class="mb-4">
      Notifications
    </h1>
    <p class="mb-6">
      Important events related to your account.
    </p>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="filter"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search"
          class="mb-4"
          type="text"
          hide-details="auto"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-col>
      <v-col class="d-none d-md-block text-right">
        <v-btn
          fab
          small
          @click="refresh"
        >
          <v-icon>
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading notifications..."
      no-data-text="No notifications yet"
      hide-default-header
      hide-default-footer
    >
    </v-data-table>
  </div>
</template>

<script>
import { mdiMagnify, mdiRefresh } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiRefresh,
      },
    }
  },
  data() {
    return {
      headers: [
        { text: 'Title', value: 'title' },
      ],
      items: [],
      loading: false,
      filter: undefined,
    }
  },
  methods: {
    refresh() {},
  },
}
</script>
